:root {
    --color-primary: #E3B517;
    --color-secondary: #DCB071;
    --color-dark: #132143;
    --color-accent: #0E9AC1;
    --color-highlight: #E20545;
    --font-main: 'Roboto', sans-serif;
    --font-heading: 'Poppins', sans-serif;
  }
  
  body {
    font-family: var(--font-main);
    color: var(--color-dark);
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
    color: var(--color-dark);
  }
  
  a {
    color: var(--color-accent);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: var(--color-dark);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: var(--color-secondary);
  }