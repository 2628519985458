.game-showcase {
    padding: 4rem 0;
  }
  
  .game-showcase h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .game-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .game-card {
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .game-card:hover {
    transform: translateY(-5px);
  }
  
  .game-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .game-card h3 {
    padding: 1rem;
    margin: 0;
    color: var(--color-dark);
  }
  
  .game-card p {
    padding: 0 1rem 1rem;
    color: #666;
  }
  
  .game-platforms {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  
  .game-platforms i {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    color: var(--color-accent);
  }
  
  .game-links {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  
  .game-links .btn {
    flex: 1;
    margin: 0 0.5rem;
    text-align: center;
  }
  
  .btn-download {
    background-color: var(--color-primary);
  }
  
  .btn-store {
    background-color: var(--color-accent);
    color: white;
  }