.header {
  background-color: var(--color-dark);
  padding: 1rem 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-primary);
}

.logo-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

nav ul li {
  margin-left: 1rem;
}

nav ul li a {
  color: var(--color-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .header .container {
    flex-direction: column;
  }

  nav {
    margin-top: 1rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 0.5rem 0;
  }
}