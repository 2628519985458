.footer {
    background-color: var(--color-dark);
    color: var(--color-secondary);
    padding: 2rem 0;
    margin-top: 2rem;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo a {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    text-decoration: none;
  }
  
  .footer-logo .logo-image {
    width: 60px;
    height: auto;
    margin-right: 10px;
  }
  
  .footer-logo .logo-text {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .footer-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .footer-nav li {
    margin-right: 1rem;
  }
  
  .footer-nav a {
    color: var(--color-secondary);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-nav a:hover {
    color: var(--color-primary);
  }
  
  .social-links {
    display: flex;
  }
  
  .social-links a {
    color: var(--color-secondary);
    font-size: 1.5rem;
    margin-left: 1rem;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: var(--color-primary);
  }
  
  .copyright {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-nav ul {
      flex-direction: column;
      margin-top: 1rem;
    }
  
    .footer-nav li {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  
    .social-links {
      margin-top: 1rem;
    }
  
    .social-links a {
      margin: 0 0.5rem;
    }
  }