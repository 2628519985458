.hero {
    background-color: var(--color-dark);
    color: white;
    padding: 4rem 0;
    text-align: center;
  }
  
  .hero-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--color-primary);
  }
  
  .hero .tagline {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: var(--color-secondary);
  }
  
  .hero .btn {
    font-size: 1.2rem;
    padding: 12px 24px;
  }