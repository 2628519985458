.contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  textarea {
    height: 150px;
    resize: vertical;
  }
  
  .error {
    color: var(--color-highlight);
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .btn-submit {
    background-color: var(--color-primary);
    color: var(--color-dark);
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: var(--color-secondary);
  }