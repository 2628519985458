.privacy-page {
    padding: 2rem 0;
  }
  
  .privacy-content section {
    margin-bottom: 2rem;
  }
  
  .privacy-content h2 {
    color: var(--color-dark);
    margin-bottom: 1rem;
  }
  
  .privacy-content p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .privacy-footer {
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    font-size: 0.9rem;
    color: #666;
  }
  
  .privacy-footer a {
    color: var(--color-accent);
  }
  
  .privacy-footer a:hover {
    text-decoration: underline;
  }